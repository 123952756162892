@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-image-gallery/styles/css/image-gallery.css";

html {
  scroll-behavior: smooth; 
}

@layer components {
  .navbar-item {
    @apply p-2 mx-1 hover:text-red-600 transition-all duration-300;
  }
}

@layer utilities {
  .animation-delay-500 {
    animation-delay: 400ms;
  }
  .animation-delay-1000 {
    animation-delay: 800ms;
  }
}

.scrollbar-hide{
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}